<template lang="">
  <v-form ref="form" v-model="validForm" lazy-validation>
    <v-row justify="start">
      <v-col cols="12">
        <base-subheading
          title="Antes de confirmar, su reserva necesita los siguientes datos:"
          mobileSize="text-h6"
        />
      </v-col>

      <v-col cols="12" md="6">
        <base-text-field
          v-model="client_data.client_name"
          prepend-inner-icon="mdi-account"
          :rules="emptyRule"
          label="Nombre"
          hide-details="auto"
        />
      </v-col>

      <v-col cols="12" md="6">
        <base-text-field
          v-model="client_data.client_email"
          prepend-inner-icon="mdi-email"
          :rules="emailRules"
          label="Email"
          hide-details="auto"
        />
      </v-col>

      <v-col cols="12" md="6">
        <base-text-field
          v-model.number="client_data.client_phone"
          prepend-inner-icon="mdi-phone"
          type="number"
          :rules="emptyRule"
          label="Teléfono"
          hide-details="auto"
        />
      </v-col>

      <v-col cols="12">
        <accept-terms-checkbox :rules="emptyRule" />
      </v-col>

      <v-col cols="12" class="pt-8">
        <v-row justify="center">
          <base-btn
            :disabled="!validForm"
            :loading="loadingSubmit"
            @click="validateForm"
          >
            Enviar Reserva
          </base-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import AcceptTermsCheckbox from "@/components/reservation/submit-booking/AcceptTermsCheckbox.vue";

import { reservationsService } from "@/services";

const { createBooking } = reservationsService;
export default {
  props: {
    shiftDetails: {
      type: Object,
      default: function () {
        return {
          date: null,
          qtyPerson: null,
          shift: null,
        };
      },
    },
  },

  data: () => {
    return {
      client_data: {
        client_name: "",
        client_phone: "",
        client_email: "",
      },
      loadingSubmit: false,
      validForm: true,
      emptyRule: [(v) => !!v || "Necesario"],
      emailRules: [
        (v) => !!v || " El correo es requerido",
        (v) => /.+@.+/.test(v) || "El E-mail debe ser valido",
      ],
    };
  },

  computed: {
    payload() {
      return {
        date: this.shiftDetails.date,
        qty_person: this.shiftDetails.qtyPerson,
        shift: this.shiftDetails.shift,
        client_data: this.client_data,
      };
    },
  },

  components: {
    AcceptTermsCheckbox,
  },

  methods: {
    createBooking,
    validateForm() {
      if (this.$refs.form.validate()) {
        this.submitReservation();
      }
    },
    submitReservation() {
      this.loadingSubmit = true;

      this.createBooking(this.payload)
        .then(() => {
          this.$router.push("/reserva/gracias");
        })
        .catch((e) => {
          const payload = {
            msg: e?.response?.data?.message?.name[0]
              ? e?.response?.data?.message?.name[0]
              : "Hubo un error, intente de nuevo.",
            success: false,
          };
          this.$store.commit("user/OPEN_SNACKBAR", payload);
        })
        .finally(() => {
          this.loadingSubmit = false;
        });
    },
  },
};
</script>
