<template>
  <v-col cols="6" sm="3">
    <v-btn
      v-if="available && id === selectedId"
      block
      class="px-2 font-weight-bold primary"
      outlined
      rounded
      color="white"
      style="border-width: 0.2rem; pointer-events: none"
    >
      {{ displayTime }}&nbsp;
      <v-icon small> mdi-check-bold </v-icon>
    </v-btn>
    <v-btn
      v-else-if="available && id !== selectedId"
      @click="$emit('update:selectedId', id)"
      block
      class="px-2"
      outlined
      rounded
      color="primary"
    >
      {{ displayTime }}
    </v-btn>
    <v-btn v-else disabled block class="px-2" outlined rounded color="primary">
      {{ displayTime }}
    </v-btn>
  </v-col>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
    available: {
      type: Boolean,
      default: false,
    },
    startTime: {
      type: String,
      default: "",
    },
    selectedId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    displayTime() {
      return this.startTime.slice(0, this.startTime.length - 3);
    },
  },
};
</script>
